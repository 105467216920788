define('m07/controllers/contact', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    debug: true,
    message: "",
    sname: "",
    email: "",
    testemail: "",

    sendingMail: false,

    nachrichtVerfassen: true,

    actions: {

      sendMessage: function sendMessage() {

        var self = this;

        if (self.get('testemail') === "") {

          console.log('sendMessage()');

          self.set('sendingMail', true);

          var messageToSend = self.get('message');
          var nameOfSender = self.get('sname');
          var email = self.get('email');

          var application = self.controllerFor('application');
          var lasteinwirkung = self.controllerFor('lasteinwirkung');

          // var applicationdata = application.get('model').application.objectAt(0);
          // var applicationdataJSON = JSON.stringify(applicationdata);
          //
          // var projektdatendata = application.get('model').projektdaten.objectAt(0);
          // var projektdatendataJSON = JSON.stringify(projektdatendata);
          //
          // var geometriedata = application.get('model').geometrie.objectAt(0);
          // var geometriedataJSON = JSON.stringify(geometriedata);
          //
          // var bauteile = self.controllerFor('bauteile');
          // var bauteiledata = application.get('model').bauteile.objectAt(0);
          // var bauteiledataJSON = JSON.stringify(bauteiledata);
          //
          // var schraubenausrichtungdata = application.get('model').schraubenausrichtung.objectAt(0);
          // var schraubenausrichtungdataJSON = JSON.stringify(schraubenausrichtungdata);
          //
          // var lasteinwirkungdata = application.get('model').lasteinwirkung.objectAt(0);
          // var lasteinwirkungdataJSON = JSON.stringify(lasteinwirkungdata);
          //
          // var uebergabedaten = $.extend(true, JSON.parse(geometriedataJSON), JSON.parse(bauteiledataJSON), JSON.parse(schraubenausrichtungdataJSON), JSON.parse(lasteinwirkungdataJSON));
          //
          // var shearconnection = {
          //   "ConnectionTyp": geometriedata.get('ConnectionType'),
          //   "von1zu2": true,
          //   "ZwischenSchichtVorh": false,
          //   "VerstaerkungVorh": false,
          //   "ShearPlaneMultiplicator": geometriedata.get('ShearPlaneMultiplicator'),
          //   "Setztiefe": geometriedata.get('Setztiefe'),
          //   "ShowMessagesOnError": false
          // };
          //
          //
          // if (lasteinwirkung.get('stahl')) {
          //   var timberelement1 = {
          //     "Querschnittswerte": {
          //       "t_Fastener": bauteiledata.get('TimberElement2_b'),
          //       "b": bauteiledata.get('TimberElement2_b'),
          //       "h": bauteiledata.get('TimberElement2_h'),
          //       "QuerschnittsID": 1
          //     },
          //     // "ALFA_RAD_LoadGrain": '0.0',
          //     // "Predrilled": geometriedata.get('Predrilled')
          //   };
          // } else {
          //   var timberelement1 = {
          //     "Querschnittswerte": {
          //       "t_Fastener": bauteiledata.get('TimberElement1_b'),
          //       "b": bauteiledata.get('TimberElement1_b'),
          //       "h": bauteiledata.get('TimberElement1_h'),
          //       "QuerschnittsID": 1
          //     },
          //     // "ALFA_RAD_LoadGrain": '0.0',
          //     // "Predrilled": geometriedata.get('Predrilled')
          //   };
          // }
          //
          // var timberelement2 = {
          //   "Querschnittswerte": {
          //     "t_Fastener": bauteiledata.get('TimberElement2_b'),
          //     "b": bauteiledata.get('TimberElement2_b'),
          //     "h": bauteiledata.get('TimberElement2_h'),
          //     "QuerschnittsID": 1
          //   },
          //   // "ALFA_RAD_LoadGrain": '0.0',
          //   // "Predrilled": geometriedata.get('Predrilled')
          // };
          //
          // var interlayerelement = {
          //   "Querschnittswerte": {
          //     "t_Fastener": '0.0',
          //     "b": '0.0',
          //     "h": '0.0',
          //     "QuerschnittsID": 1
          //   },
          //   // "ALFA_RAD_LoadGrain": '0.0',
          //   // "Predrilled": false
          // };
          //
          // var steelplate = {
          //   "Querschnittswerte": {
          //     "t_Fastener": bauteiledata.get('TimberElement1_b'),
          //     "b": bauteiledata.get('TimberElement1_b'),
          //     "h": bauteiledata.get('TimberElement1_h')
          //   },
          //   "Bezeichnung": self.getStahlname(Number(bauteile.get('htfkl'))),
          //   "f_yk": Number(bauteile.get('htfkl')).toFixed(1),
          // };
          //
          // uebergabedaten.ShearConnection = shearconnection;
          // uebergabedaten.TimberElement1 = timberelement1;
          // uebergabedaten.TimberElement2 = timberelement2;
          // uebergabedaten.InterlayerElement = interlayerelement;
          // uebergabedaten.SteelPlate = steelplate;
          //
          // var JSONdata = JSON.stringify(uebergabedaten);
          var JSONdata = JSON.stringify(self.controllerFor('supercontroller').uebergabedatenErzeugen());

          var server = application.get('server');
          var pfad = application.get('pfad');

          var initialized = applicationdata.get('initialized');

          var cid = applicationdata.get('Calculation_ID');

          if (self.debug) {
            console.log('cid: ' + cid);
            console.log('server: ' + server);
            console.log('pfad: ' + pfad);
            console.log('nameOfSender: ' + nameOfSender);
            console.log('messageToSend: ');
            console.log(messageToSend);
            console.log('kennung: ');
            console.log(applicationdataJSON);
            console.log('paras:');
            console.log(JSONdata);
          }

          $.ajax({
            type: "POST",
            url: server + "sendeEmail/",
            data: {
              kennung: applicationdataJSON,
              paras: JSONdata,
              cid: cid,
              body: messageToSend,
              name: nameOfSender,
              subj: 'M07 HTML CLIENT - Kontaktformular / Anfrage',
              sender: email,
              receiver: "support@swg-engineering.de",
              suffix: "wu07"
            }
          }).done(function (data) {
            self.set('sendingMail', false);
            self.set('nachrichtVerfassen', false);
            console.log(data);
            // datei öffnen, bzw. link anzeigen
          });
        }
      },

      neueNachrichtVerfassen: function neueNachrichtVerfassen() {
        var self = this;
        self.set('message', "");
        this.transitionToRoute('projektdaten');
        self.set('nachrichtVerfassen', true);
      }

    },

    getStahlname: function getStahlname(index) {
      var self = this;
      var pklassenDB = fklDB.fkl_stahl;

      var tmp = "";
      var len = pklassenDB.length;

      for (var i = 0; i < len; i++) {
        if (pklassenDB[i]["fklind"] === index) {
          tmp = pklassenDB[i]["fkl"];
        }
      }
      return tmp;
    }

  });

});