define('m07/controllers/bauteile', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {
    debug: true,
    openFromFile: false,
    c26: false,
    displayErrors: true,
    material: {},
    honk: null,
    ttHoeheHT: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 2.0 - 9999.9 [cm]";
    }),
    ttBreiteHT: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 2.4 - 9999.9 [cm]";
      },
      set: function set(key, value) {
        return value;
      }

    }),
    ttHoeheNT: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 2.0 - 9999.9 [cm]";
    }),
    ttBreiteNT: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 2.0 - 9999.9 [cm]";
    }),
    ttWinkel: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.0 - 90.0 [°]";
    }),
    i18n: Ember['default'].inject.service(),
    self: undefined,
    hauptnebentraeger: '',
    htbreite: "",
    hthoehe: "",
    ntbreite: "",
    nthoehe: "",
    toolTip: "test tooltip",
    htfkl: 5,
    ntfkl: 5,
    htholz: 0,
    ntholz: 0,
    httraeger: true,
    winkel: "",
    ntStahl: false,
    htStahl: false,
    laubholz: false,
    winkelDisabled: true,

    materialienOption: 1,

    validations: {
      htbreite: {
        numericality: {
          greaterThanOrEqualTo: 2.0,
          lessThanOrEqualTo: 9999.9
        }
      },
      hthoehe: {
        numericality: {
          greaterThanOrEqualTo: 2.0,
          lessThanOrEqualTo: 9999.9
        }
      },
      ntbreite: {
        numericality: {
          greaterThanOrEqualTo: 2.0,
          lessThanOrEqualTo: 9999.9
        }
      },
      nthoehe: {
        numericality: {
          greaterThanOrEqualTo: 2.0,
          lessThanOrEqualTo: 9999.9
        }
      },
      winkel: {
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 90.0
        }
      }
    },

    traeger: [{
      name: "Haupt-/Nebenträger",
      value: true
    }, {
      name: "Stütze/Nebenträger",
      value: false
    }],

    htmaterial: [{
      name: "Vollholz Nadelholz",
      ind: 0
    }, {
      name: "Brettschichtholz homogen",
      ind: 1
    }, {
      name: "Brettschichtholz kombiniert",
      ind: 13
    }],

    htmaterialholz: [{
      name: "Vollholz Nadelholz",
      ind: 0
    }, {
      name: "Brettschichtholz homogen",
      ind: 1
    }, {
      name: "Brettschichtholz kombiniert",
      ind: 13
    }],

    htmaterialStahl: [{
      name: "Stahl",
      ind: 0
    }],

    ntmaterial: [],

    htfklarray: [],
    ntfklarray: [],

    minimalDickenDB: {
      0: 2.4,
      1: 2.4,
      13: 2.4,
      21: 0.6,
      20: 0.6,
      7: 0.6,
      8: 0.8,
      12: 2.4,
      1000: 0.1,
      30: 2.4
    },

    materialbezeichnung: "",

    vgebohrt: [],
    te1vorgebohrt: false,
    te1laubholz: false,
    te2vorgebohrt: false,
    te2laubholz: false,

    te1vorgebohrtHilfetext: "",
    te1vorgebohrtHilfetextFocused: false,
    te2vorgebohrtHilfetext: "",
    te2vorgebohrtHilfetextFocused: false,

    initTrigger: false,

    init: function init() {
      this._super();
      var self = this;

      self.setSelectFieldsContent();

      this.set('winkel', "0.0");

      this.set('haupthauptTraeger1', this.get('i18n').t('bemessungslast').toString());
    },

    setValues: function setValues(values) {

      var self = this;

      if (self.debug) {
        console.log("values in bauteile: ");
        console.log(values);
      }

      var htbreiteEvent = {
        target: {
          name: "htbreite"
        }
      };
      var hthoeheEvent = {
        target: {
          name: "hthoehe"
        }
      };
      var ntbreiteEvent = {
        target: {
          name: "ntbreite"
        }
      };
      var nthoeheEvent = {
        target: {
          name: "nthoehe"
        }
      };
      var winkelEvent = {
        target: {
          name: "winkel"
        }
      };

      var setztiefeEvent = {
        target: {
          name: "setztiefe"
        }
      };
      this.setX3D(parseInt(values.Setztiefe), setztiefeEvent);

      this.set('openFromFile', true);

      if (values.ConnectionType === "HH" || values.ConnectionType === "HHH" || values.ConnectionType === "HSH") {
        this.set('htholz', Number(values.MatKeyTimberElement1));
        this.set('htfkl', Number(values.FKLKeyTimberElement1));

        this.set('ntholz', Number(values.MatKeyTimberElement2));
        this.set('ntfkl', Number(values.FKLKeyTimberElement2));
      } else {
        this.set('htholz', Number(values.MatKeyTimberElement2));
        this.set('htfkl', Number(values.FKLKeyTimberElement2));

        this.set('ntholz', Number(values.MatKeyTimberElement1));
        this.set('ntfkl', Number(values.FKLKeyTimberElement1));
      }

      this.set('htbreite', values.TimberElement1_b);
      this.setX3D(parseFloat(values.TimberElement1_b), htbreiteEvent);

      this.set('hthoehe', values.TimberElement1_h);
      this.setX3D(parseFloat(values.TimberElement1_h), hthoeheEvent);

      this.set('httraeger', values.HtRiegel);

      this.set('winkel', values.ALFA_Elements_GRAD);
      this.setX3D(parseFloat(values.ALFA_Elements_GRAD), winkelEvent);

      this.set('ntbreite', values.TimberElement2_b);
      this.setX3D(parseFloat(values.TimberElement2_b), ntbreiteEvent);

      this.set('nthoehe', values.TimberElement2_h);
      this.setX3D(parseFloat(values.TimberElement2_h), nthoeheEvent);

      this.send('validation', -1, { target: { name: "FOO" } });

      this.set('openFromFile', false);
    },

    setSelectFieldsContent: function setSelectFieldsContent() {
      var self = this;
      self.setHtMaterialien();
      self.setHtFKL();
      self.setNtMaterialien();
      self.setNtFKL();
      self.setVgebohrt();
    },

    setHtMaterialien: function setHtMaterialien() {
      var self = this;
      var indices = [1];

      if (self.get('materialienOption') === 1) {
        indices = [0, 1, 13, 12, 30];
      } else if (self.get('materialienOption') === 2) {
        indices = [1000];
      } else if (self.get('materialienOption') === 3) {
        indices = [0, 1, 13, 12, 30];
      } else if (self.get('materialienOption') === 4) {
        indices = [0, 1, 13, 12, 30];
      } else if (self.get('materialienOption') === 5) {
        indices = [1000];
      } else if (self.get('materialienOption') === 6) {
        indices = [0, 1, 13, 12, 30];
      }

      self.set('htmaterial', self.getSelectFieldContent('materialienDB', indices));
    },

    setHtFKL: function setHtFKL() {
      var self = this;
      var indices = [];

      if (this.get('htholz') === 1) {
        indices = [20, 21, 24, 22];
        self.set('htfklarray', self.getSelectFieldContent('fkl_bsh', indices));
        self.set('htfkl', 20);
      } else if (self.get('htholz') === 13) {
        indices = [30, 31, 34, 32];
        self.set('htfklarray', self.getSelectFieldContent('fkl_bsh_kombi', indices));
        self.set('htfkl', 30);
      } else if (this.get('htholz') === 0) {
        indices = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
        self.set('htfklarray', self.getSelectFieldContent('fkl_vh', indices));
        self.set('htfkl', 5);
      } else if (this.get('htholz') === 30) {
        indices = [301];
        self.set('htfklarray', self.getSelectFieldContent('fkl_baubuche', indices));
        self.set('htfkl', 301);
      } else if (this.get('htholz') === 21) {
        indices = [110, 111, 112, 113];
        self.set('htfklarray', self.getSelectFieldContent('fkl_baufurniersperrholz', indices));
        self.set('htfkl', 110);
      } else if (this.get('htholz') === 20) {
        indices = [125, 126, 127];
        self.set('htfklarray', self.getSelectFieldContent('fkl_osb_platten', indices));
        self.set('htfkl', 125);
      } else if (this.get('htholz') === 7) {
        indices = [160, 170, 180, 190];
        self.set('htfklarray', self.getSelectFieldContent('fkl_kunstharzgebundene_holzspanplatten', indices));
        self.set('htfkl', 160);
      } else if (this.get('htholz') === 8) {
        indices = [200];
        self.set('htfklarray', self.getSelectFieldContent('fkl_zementgebundene_holzspanplatten', indices));
        self.set('htfkl', 200);
      } else if (this.get('htholz') === 12) {
        indices = [50, 51, 52];
        self.set('htfklarray', self.getSelectFieldContent('fkl_vollholz_laubholz', indices));
        self.set('htfkl', 50);
      } else if (this.get('htholz') === 1000) {
        indices = [23.5, 35.5];
        self.set('htfklarray', self.getSelectFieldContent('fkl_stahl', indices));
        self.set('htfkl', 23.5);
      }
    },

    setNtMaterialien: function setNtMaterialien() {
      var self = this;
      var indices = [];

      if (self.get('materialienOption') === 1) {
        indices = [0, 1, 13, 12, 30];
      } else if (self.get('materialienOption') === 2) {
        indices = [0, 1, 13, 12, 30];
      } else if (self.get('materialienOption') === 3) {
        indices = [0, 1, 13, 12, 30];
      } else if (self.get('materialienOption') === 4) {
        indices = [0, 1, 13, 30, 21, 20, 7, 8, 12];
      } else if (self.get('materialienOption') === 5) {
        indices = [0, 1, 13, 12, 30];
      } else if (self.get('materialienOption') === 6) {
        indices = [1000];
      }

      self.set('ntmaterial', self.getSelectFieldContent('materialienDB', indices));
    },

    setNtFKL: function setNtFKL() {
      var self = this;
      var indices = [];

      if (this.get('ntholz') === 1) {
        indices = [20, 21, 24, 22];
        self.set('ntfklarray', self.getSelectFieldContent('fkl_bsh', indices));
        self.set('ntfkl', 20);
      } else if (self.get('ntholz') === 13) {
        indices = [30, 31, 34, 32];
        self.set('ntfklarray', self.getSelectFieldContent('fkl_bsh_kombi', indices));
        self.set('ntfkl', 30);
      } else if (this.get('ntholz') === 0) {
        indices = [0, 1, 2, 3, 4, 5, 6, 7];
        self.set('ntfklarray', self.getSelectFieldContent('fkl_vh', indices));
        self.set('ntfkl', 5);
      } else if (this.get('ntholz') === 30) {
        indices = [301];
        self.set('ntfklarray', self.getSelectFieldContent('fkl_baubuche', indices));
        self.set('ntfkl', 301);
      } else if (this.get('ntholz') === 12) {
        indices = [50, 51, 52];
        self.set('ntfklarray', self.getSelectFieldContent('fkl_vollholz_laubholz', indices));
        self.set('ntfkl', 50);
      }
    },

    setVgebohrt: function setVgebohrt() {
      var self = this;
      var indices = [false, true];
      self.set('vgebohrt', self.getSelectFieldContent('vgebohrt', indices));
    },

    getSelectFieldContent: function getSelectFieldContent(db, indexes) {
      var self = this;
      var tmp = [];
      var dbContent = self.getDBContent(db);
      for (var i = 0; i < indexes.length; i++) {
        for (var k = 0; k < dbContent.length; k++) {
          if (dbContent[k].id === indexes[i]) {
            tmp.push({ id: dbContent[k].id, name: this.get('i18n').t(dbContent[k].name) });
          }
        }
      }

      return tmp;
    },

    getDBContent: function getDBContent(db) {
      var dbContent = [];
      switch (db) {
        case 'materialienDB':
          dbContent = materialienDB.materialien_de_m03;
          break;
        case 'fkl_vh':
          dbContent = fklDB_new.fkl_vh.fklassen;
          break;
        case 'fkl_bsh':
          dbContent = fklDB_new.fkl_bsh.fklassen;
          break;
        case 'fkl_bsh_kombi':
          dbContent = fklDB_new.fkl_bsh_kombi.fklassen;
          break;
        case 'fkl_baubuche':
          dbContent = fklDB_new.fkl_baubuche.fklassen;
          break;
        case 'fkl_baufurniersperrholz':
          dbContent = fklDB_new.fkl_baufurniersperrholz.fklassen;
          break;
        case 'fkl_osb_platten':
          dbContent = fklDB_new.fkl_osb_platten.fklassen;
          break;
        case 'fkl_kunstharzgebundene_holzspanplatten':
          dbContent = fklDB_new.fkl_kunstharzgebundene_holzspanplatten.fklassen;
          break;
        case 'fkl_zementgebundene_holzspanplatten':
          dbContent = fklDB_new.fkl_zementgebundene_holzspanplatten.fklassen;
          break;
        case 'fkl_vollholz_laubholz':
          dbContent = fklDB_new.fkl_vollholz_laubholz.fklassen;
          break;
        case 'fkl_stahl':
          dbContent = fklDB_new.fkl_stahl.fklassen;
          break;
        case 'vgebohrt':
          dbContent = vorgebohrt.auswahlfeld;
          break;
      }
      return dbContent;
    },

    getValueFromSelectField: function getValueFromSelectField(contentArray, index) {
      var self = this;

      var tmp = "";
      var len = contentArray.length;

      for (var i = 0; i < len; i++) {
        if (contentArray[i].id === index) {
          tmp = contentArray[i].name;
        }
      }
      return tmp;
    },

    watchNumericalInputs: (function () {

      if (!this.openFromFile) {

        if (this.get('htbreite').indexOf(",") !== -1) {
          this.set('htbreite', this.get('htbreite').replace(",", "."));
          this.setfuehrendeNull('htbreite');
        }

        if (this.get('hthoehe').indexOf(",") !== -1) {
          this.set('hthoehe', this.get('hthoehe').replace(",", "."));
          this.setfuehrendeNull('hthoehe');
        }

        if (this.get('ntbreite').indexOf(",") !== -1) {
          this.set('ntbreite', this.get('ntbreite').replace(",", "."));
          this.setfuehrendeNull('ntbreite');
        }

        if (this.get('nthoehe').indexOf(",") !== -1) {
          this.set('nthoehe', this.get('nthoehe').replace(",", "."));
          this.setfuehrendeNull('nthoehe');
        }

        if (this.get('winkel').indexOf(",") !== -1) {
          this.set('winkel', this.get('winkel').replace(",", "."));
          this.setfuehrendeNull('winkel');
        }
      }
    }).observes('htbreite', 'hthoehe', 'ntbreite', 'nthoehe', 'winkel'),

    setfuehrendeNull: function setfuehrendeNull(ind) {
      if (this.get(ind).charAt(0) === '.') {
        this.set(ind, '0.');
      }
    },

    changeHtSt: (function () {
      this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('istHt', this.get('httraeger'));
    }).observes('httraeger'),

    istHt: (function () {
      return this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('istHt');
    }).property('httraeger'),

    watchHtMaterial: (function () {
      var self = this;

      // this.set('htfklarray', self.getFklassen(self.get('htholz')));
      // this.set('htfkl', parseInt(self.getDefaultValue(self.get('htholz'))));

      // if (self.get('htholz') === 12) {
      //   self.set('laubholz', true);
      // } else {
      //   self.set('laubholz', false);
      // }

      self.set('te1laubholz', vorgebohrt.materialien.findBy('id', this.get('htholz')).laubholz);
      self.set('te1vorgebohrt', vorgebohrt.materialien.findBy('id', this.get('htholz')).value);

      var tmp = self.minimalDickenDB[self.get('htholz')];

      self.set('validations.htbreite.numericality.greaterThanOrEqualTo', parseFloat(tmp));

      console.log('htStahl: ' + self.get('htStahl'));

      if (self.get('htStahl')) {
        self.set('validations.htbreite.numericality.greaterThanOrEqualTo', 0.1);
        self.set('validations.htbreite.numericality.lessThanOrEqualTo', 4.0);
        self.set('ttBreiteHT', self.get('i18n').t('wertebereich') + " 0.1 - 4 [cm]");
      } else {
        self.set('validations.htbreite.numericality.greaterThanOrEqualTo', parseFloat(tmp));
        self.set('validations.htbreite.numericality.lessThanOrEqualTo', 9999.9);
        self.set('ttBreiteHT', self.get('i18n').t('wertebereich') + tmp + " - 9999.9 [cm]");
      }

      this.set('openFromFile', true);
      this.send('validation');
      this.set('openFromFile', false);

      self.setHtFKL();

      self.send("setSchraubenbildWerte", "TE1", "0", "45", "45", "variante1focused", "variante2focused");
    }).observes('htholz'),

    watchNtMaterial: (function () {
      var self = this;

      // this.set('ntfklarray', self.getFklassen(self.get('ntholz')));
      // this.set('ntfkl', parseInt(self.getDefaultValue(self.get('ntholz'))));

      // if (self.get('ntholz') === 12) {
      //   self.set('laubholz', true);
      // } else {
      //   self.set('laubholz', false);
      // }

      self.set('te2laubholz', vorgebohrt.materialien.findBy('id', this.get('ntholz')).laubholz);
      self.set('te2vorgebohrt', vorgebohrt.materialien.findBy('id', this.get('ntholz')).value);

      var tmp = self.minimalDickenDB[self.get('ntholz')];

      self.set('validations.ntbreite.numericality.greaterThanOrEqualTo', parseFloat(tmp));
      self.set('ttBreiteNT', self.get('i18n').t('wertebereich') + tmp + " - 9999.9 [cm]");

      self.setNtFKL();

      self.send("setSchraubenbildWerte", "TE2", "0", "45", "45", "variante3focused", "variante4focused");
    }).observes('ntholz'),

    setX3D: function setX3D(value, event) {
      var self = this;

      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      var geometrie = this.controllerFor('geometrie');

      value = parseFloat(value).toFixed(1);

      switch (event.target.name) {
        case 'ntbreite':
          if (this.errors.ntbreite.length === 0) {
            bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).set('z', value);
            x3d.set('xMittelbauteil', value * 4 / 3);
            application.get('model').x3ddefault.objectAt(0).set('ntBreiteEingetragen', true);
            geometrie.setSchraubenlaenge();
          } else {
            application.get('model').x3ddefault.objectAt(0).set('ntBreiteEingetragen', false);
          }
          break;
        case 'nthoehe':
          if (this.errors.nthoehe.length === 0) {
            bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).set('y', value);
            application.get('model').x3ddefault.objectAt(0).set('ntHoeheEingetragen', true);
          } else {
            application.get('model').x3ddefault.objectAt(0).set('ntHoeheEingetragen', false);
          }
          break;
        case 'htbreite':
          if (this.errors.htbreite.length === 0) {
            bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).set('z', value);
            bauteile.findBy('id', 'hauptTraeger2').get('boxsizes').objectAt(0).set('z', value);
            x3d.set('xSeitenbauteil', value * 2 / 3);
            application.get('model').x3ddefault.objectAt(0).set('htBreiteEingetragen', true);
            geometrie.setSchraubenlaenge();
          } else {
            application.get('model').x3ddefault.objectAt(0).set('htBreiteEingetragen', false);
          }
          break;
        case 'hthoehe':
          if (this.errors.hthoehe.length === 0) {
            bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).set('y', value);
            bauteile.findBy('id', 'hauptTraeger2').get('boxsizes').objectAt(0).set('y', value);
            application.get('model').x3ddefault.objectAt(0).set('htHoeheEingetragen', true);
          } else {
            application.get('model').x3ddefault.objectAt(0).set('htHoeheEingetragen', false);
          }
          break;
        case 'winkel':
          if (this.errors.winkel.length === 0) {
            x3d.set('vWinkel', value);
            application.get('model').x3ddefault.objectAt(0).set('vWinkelEingetragen', true);
          } else {
            application.get('model').x3ddefault.objectAt(0).set('vWinkelEingetragen', false);
          }
          break;
      }

      // var schraubenausrichtung = this.controllerFor('schraubenausrichtung');
      // schraubenausrichtung.watchSchraubenausrichtung();

      x3d.set('ergebnisGeladen', false);
      x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
      x3d.set('arc2dPositionTriggern', !x3d.get('arc2dPositionTriggern'));
      x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
      x3d.set('transformHelper', !x3d.get('transformHelper'));
      self.controllerFor('application').zentriertObjekt();
    },

    watchComboboxes: (function () {

      var self = this;

      self.controllerFor('supercontroller').resetApplication();

      var bauteile = this.controllerFor('application').get('model').bauteile.objectAt(0);

      if (self.get('htStahl') === true) {
        bauteile.set('MatKeyTimberElement1', this.get('ntholz'));
        bauteile.set('FKLKeyTimberElement1', this.get('ntfkl'));

        bauteile.set('MatKeyTimberElement2', this.get('htholz'));
        bauteile.set('FKLKeyTimberElement2', this.get('htfkl'));
      } else {
        bauteile.set('MatKeyTimberElement1', this.get('htholz'));
        bauteile.set('FKLKeyTimberElement1', this.get('htfkl'));

        bauteile.set('MatKeyTimberElement2', this.get('ntholz'));
        bauteile.set('FKLKeyTimberElement2', this.get('ntfkl'));
      }

      bauteile.set('HtRiegel', this.get('httraeger'));

      bauteile.set('materialbezeichnung', self.get('htfklarray').findBy('id', self.get('htfkl')).name.toString());

      self.controllerFor('lasteinwirkung').setNklassen();
      self.controllerFor('lasteinwirkung').send('validation', self.controllerFor('lasteinwirkung').get('vorbelastung'), { target: { name: "foobar" } });
    }).observes('htholz', 'htfkl', 'ntholz', 'ntfkl', 'httraeger'),

    setSchraubenlaenge: function setSchraubenlaenge() {
      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = application.get('model').x3ddefault.objectAt(0).get('bauteile');

      var ntBreite = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z');
      var htBreite = bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('z');

      var schraubenLaenge = 0;

      var geometrie = this.controllerFor('geometrie');

      if (x3d.get('zweiSchnittig')) {
        schraubenLaenge = (Number(htBreite) + Number(ntBreite) + Number(htBreite) / 2 - Number(geometrie.get('setztiefe'))) / Math.cos(Number(bauteile.findBy('id', 'schraubeStandard1').get('rotations').objectAt(0).get('y')));
        var z = 0 - (Number(htBreite) + Number(ntBreite)) / 2;
        bauteile.findBy('id', 'schraubeStandard1').get('translations').objectAt(0).set('z', z);
      } else {
        schraubenLaenge = (Number(htBreite) + Number(ntBreite) / 2 - Number(geometrie.get('setztiefe'))) / Math.cos(Number(bauteile.findBy('id', 'schraubeStandard1').get('rotations').objectAt(0).get('y')));
        bauteile.findBy('id', 'schraubeStandard1').get('translations').objectAt(0).set('z', 0);
      }

      bauteile.findBy('id', 'schraubeStandard1').get('screwProperties').objectAt(0).set('length', schraubenLaenge);
      bauteile.findBy('id', 'schraubeStandard1').get('screwProperties').objectAt(0).set('threadlengthtip', schraubenLaenge);
      bauteile.findBy('id', 'schraubeStandard2').get('screwProperties').objectAt(0).set('length', schraubenLaenge);
      bauteile.findBy('id', 'schraubeStandard2').get('screwProperties').objectAt(0).set('threadlengthtip', schraubenLaenge);
    },

    watchTe1Vorgebohrt: (function () {

      var self = this;

      self.controllerFor('supercontroller').resetApplication();

      var bauteile = self.controllerFor('application').get('model').bauteile.objectAt(0);
      bauteile.set('TimberElement1Predrilled', self.get('te1vorgebohrt'));
    }).observes('te1vorgebohrt'),

    watchTe2Vorgebohrt: (function () {

      var self = this;

      self.controllerFor('supercontroller').resetApplication();

      var bauteile = self.controllerFor('application').get('model').bauteile.objectAt(0);
      bauteile.set('TimberElement2Predrilled', self.get('te2vorgebohrt'));
    }).observes('te2vorgebohrt'),

    htBauBuche: (function () {
      if (Number(this.get('htholz')) === 30) {
        return true;
      }
      return false;
    }).property('htholz'),

    ntBauBuche: (function () {
      if (Number(this.get('ntholz')) === 30) {
        return true;
      }
      return false;
    }).property('ntholz'),

    actions: {

      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {

          this.setX3D(value, event);
        }

        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('bauteileInvalid', false);

            self.controllerFor('supercontroller').resetApplication();

            var bauteile = self.controllerFor('application').get('model').bauteile.objectAt(0);

            bauteile.set('TimberElement1_b', parseFloat(self.get('htbreite')).toFixed(1));
            bauteile.set('TimberElement1_h', parseFloat(self.get('hthoehe')).toFixed(1));
            bauteile.set('TimberElement2_b', parseFloat(self.get('ntbreite')).toFixed(1));
            bauteile.set('TimberElement2_h', parseFloat(self.get('nthoehe')).toFixed(1));
            bauteile.set('ALFA_Elements_GRAD', parseFloat(self.get('winkel')).toFixed(1));
          } else {
            self.set('displayErrors', true);
            application.set('bauteileInvalid', true);
          }
        });
      },

      htbreiteIsSelected: function htbreiteIsSelected() {
        var htbreiteaktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('htbreiteAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('htbreiteAktiv', !htbreiteaktiv);

        if (this.get('htbreite') !== "") {
          var htbreitework = parseFloat(this.get('htbreite').replace(",", "."));
          var htbreiterounded = htbreitework.toFixed(1);
          this.set('htbreite', htbreiterounded);
          this.send('validation', htbreiterounded, { target: { name: "htbreite" } });
        }

        if (htbreiteaktiv === false) {
          document.getElementsByName('htbreite')[0].setSelectionRange(0, this.get('htbreite').length);
        }
      },

      hthoeheIsSelected: function hthoeheIsSelected() {
        var hthoeheaktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('hthoeheAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('hthoeheAktiv', !hthoeheaktiv);

        if (this.get('hthoehe') !== "") {
          var hthoehework = parseFloat(this.get('hthoehe').replace(",", "."));
          var hthoeherounded = hthoehework.toFixed(1);
          this.set('hthoehe', hthoeherounded);
          this.send('validation', hthoeherounded, { target: { name: "hthoehe" } });
        }

        if (hthoeheaktiv === false) {
          document.getElementsByName('hthoehe')[0].setSelectionRange(0, this.get('hthoehe').length);
        }
      },

      ntbreiteIsSelected: function ntbreiteIsSelected() {
        var ntbreiteaktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('ntbreiteAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('ntbreiteAktiv', !ntbreiteaktiv);

        if (this.get('ntbreite') !== "") {
          var ntbreitework = parseFloat(this.get('ntbreite').replace(",", "."));
          var ntbreiterounded = ntbreitework.toFixed(1);
          this.set('ntbreite', ntbreiterounded);
          this.send('validation', ntbreiterounded, { target: { name: "ntbreite" } });
        }

        if (ntbreiteaktiv === false) {
          document.getElementsByName('ntbreite')[0].setSelectionRange(0, this.get('ntbreite').length);
        }
      },

      nthoeheIsSelected: function nthoeheIsSelected() {
        var nthoeheaktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('nthoeheAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('nthoeheAktiv', !nthoeheaktiv);

        if (this.get('nthoehe') !== "") {
          var nthoehework = parseFloat(this.get('nthoehe').replace(",", "."));
          var nthoeherounded = nthoehework.toFixed(1);
          this.set('nthoehe', nthoeherounded);
          this.send('validation', nthoeherounded, { target: { name: "nthoehe" } });
        }

        if (nthoeheaktiv === false) {
          document.getElementsByName('nthoehe')[0].setSelectionRange(0, this.get('nthoehe').length);
        }
      },

      winkelIsSelected: function winkelIsSelected() {
        var vWinkelAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('vWinkelAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('vWinkelAktiv', !vWinkelAktiv);

        if (this.get('winkel') !== "") {
          var winkelwork = parseFloat(this.get('winkel').replace(",", "."));
          var winkelrounded = winkelwork.toFixed(1);
          this.set('winkel', winkelrounded);
          this.send('validation', winkelrounded, { target: { name: "winkel" } });
        }

        if (vWinkelAktiv === false) {
          document.getElementsByName('winkel')[0].setSelectionRange(0, this.get('winkel').length);
        }
      },

      setSchraubenbildWerte: function setSchraubenbildWerte(te, _alphaRAD_KraftFaser, _alphaRAD_vmFaser, _betaRAD_vmFurnier, focusedButton, unfocusedButton) {

        var self = this;

        self.controllerFor('supercontroller').resetApplication();

        var bauteile = this.controllerFor('application').get('model').bauteile.objectAt(0);

        bauteile.set(te + '_alphaRAD_KraftFaser', _alphaRAD_KraftFaser);
        bauteile.set(te + '_alphaRAD_vmFaser', _alphaRAD_vmFaser);
        bauteile.set(te + '_betaRAD_vmFurnier', _betaRAD_vmFurnier);

        self.set(focusedButton, true);
        self.set(unfocusedButton, false);
      },

      removeClass: function removeClass(element) {
        var self = this;
        self.set(element, false);
      }

    }, // Ende actions

    // hilfeTexte obeserver

    observes_htholzHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "htholz", self.get('htholz').toString());
      self.controllerFor("supercontroller").setHilfetexte("bauteile", hilfetextArray);
    }).observes('htholz', 'initTrigger'),

    observes_htfklHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "htfkl", "0");
      self.controllerFor("supercontroller").setHilfetexte("bauteile", hilfetextArray);
    }).observes('htfkl', 'initTrigger'),

    observes_te1vorgebohrtHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "te1vorgebohrt", "0");
      if (Number(self.get("htholz")) !== 30) {
        self.controllerFor("supercontroller").setHilfetexte("bauteile", hilfetextArray);
      }
    }).observes('te1vorgebohrt', 'initTrigger'),

    observes_ntholzHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "ntholz", self.get('ntholz').toString());
      self.controllerFor("supercontroller").setHilfetexte("bauteile", hilfetextArray);
    }).observes('ntholz', 'initTrigger'),

    observes_ntfklHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "ntfkl", "0");
      self.controllerFor("supercontroller").setHilfetexte("bauteile", hilfetextArray);
    }).observes('ntfkl', 'initTrigger'),

    observes_te2vorgebohrtHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "te2vorgebohrt", "0");
      if (Number(self.get("ntholz")) !== 30) {
        self.controllerFor("supercontroller").setHilfetexte("bauteile", hilfetextArray);
      }
    }).observes('te2vorgebohrt', 'initTrigger'),

    // hilfeTexte display properties

    display_htholzHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('htholzHilfetext'));
    }).property('htholzHilfetext'),

    display_htfklHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('htfklHilfetext'));
    }).property('htfklHilfetext'),

    display_te1vorgebohrtHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('te1vorgebohrtHilfetext'));
    }).property('te1vorgebohrtHilfetext'),

    display_ntholzHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('ntholzHilfetext'));
    }).property('ntholzHilfetext'),

    display_ntfklHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('ntfklHilfetext'));
    }).property('ntfklHilfetext'),

    display_te2vorgebohrtHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('te2vorgebohrtHilfetext'));
    }).property('te2vorgebohrtHilfetext')

  });

});