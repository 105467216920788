define('m07/controllers/schraubenausrichtung', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {

    displayErrors: true,
    einschraubwinkel: "45.0",
    winkelscheibe: false,

    schraubenausrichtung: false,
    stahl: false,

    einschraubwinkelDisabled: true,
    winkelscheibeDisabled: '',

    ttEinschraubwinkel: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 30.0 - 60.0 [°]";
    }),

    i18n: Ember['default'].inject.service(),

    validations: {
      einschraubwinkel: {
        numericality: {
          greaterThanOrEqualTo: 30.0,
          lessThanOrEqualTo: 60.0
        }
      }
    },

    contentSchraubenausrichtungen: [],
    schraubenausrichtungen: [{
      id: 0,
      name: "parallel",
      value: false,
      disabled: false
    }, {
      id: 1,
      name: "kreuz",
      value: true,
      disabled: false
    }],

    init: function init() {
      this._super();
      var self = this;

      this.set('winkelscheibeDisabled', true);
      this.setSelectFieldsContent();
    },

    setSelectFieldsContent: function setSelectFieldsContent() {
      var self = this;
      self.setContentSchraubenausrichtungen();
    },

    setContentSchraubenausrichtungen: function setContentSchraubenausrichtungen() {
      var self = this;
      var indices = [0, 1];
      self.set('contentSchraubenausrichtungen', self.getSelectFieldContent('contentSchraubenausrichtungen', indices));
    },

    getSelectFieldContent: function getSelectFieldContent(db, indexes) {
      var self = this;
      var tmp = [];
      var dbContent = self.getDBContent(db);
      for (var i = 0; i < indexes.length; i++) {
        for (var k = 0; k < dbContent.length; k++) {
          if (dbContent[k].id === indexes[i]) {
            tmp.push({ id: dbContent[k].id, name: this.get('i18n').t(dbContent[k].name), value: dbContent[k].value, disabled: dbContent[k].disabled });
          }
        }
      }

      return tmp;
    },

    getDBContent: function getDBContent(db) {
      var dbContent = [];
      switch (db) {

        case 'contentSchraubenausrichtungen':
          dbContent = this.get('schraubenausrichtungen');
          break;
      }
      return dbContent;
    },

    setValues: function setValues(values) {

      var self = this;

      if (self.debug) {
        console.log("values in bauteile: ");
        console.log(values);
      }

      this.set('openFromFile', true);

      this.set('schraubenausrichtung', values.SchraubenKreuz);
      this.set('winkelscheibe', values.WinkelScheibe);
      this.set('AngleScrewPlane_GRAD', parseInt(values.AngleScrewPlane_GRAD));

      // x3d.set('winkelscheibe', self.get('winkelscheibe'));
      // geometrie.setSchraubenlaenge();
      // x3d.set('schraubenGekreuzt', self.get('schraubenausrichtung'));
      // x3d.set('ergebnisGeladen', false);
      // x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
      // x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
      //
      this.set('openFromFile', false);
    },
    getValueFromSelectField: function getValueFromSelectField(contentArray, index) {
      var self = this;

      var tmp = "";
      var len = contentArray.length;

      for (var i = 0; i < len; i++) {
        if (contentArray[i].id === index) {
          tmp = contentArray[i].name;
        }
      }
      return tmp;
    },

    watchSchraubenausrichtung: (function () {
      var self = this;

      self.controllerFor('supercontroller').resetApplication();

      var schraubenausrichtung = self.controllerFor('application').get('model').schraubenausrichtung.objectAt(0);
      schraubenausrichtung.set('SchraubenKreuz', self.get('schraubenausrichtung'));

      var application = this.controllerFor('application');
      var geometrie = this.controllerFor('geometrie');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      var deltaX = 0;

      console.log("scherflaechenVM: " + Number(geometrie.get('scherflaechenVM')));
      if (Number(geometrie.get('scherflaechenVM')) === 2) {

        deltaX = Number(bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z')) / 2 + Number(bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z')) / 2;
      }

      self.set('SchraubenKreuz', self.get('schraubenausrichtung'));

      console.log("deltaX: " + deltaX);

      bauteile.findBy('id', 'schraubeStandard1').get('translations').objectAt(0).set('x', -deltaX);

      x3d.set('schraubenGekreuzt', self.get('schraubenausrichtung'));
      x3d.set('ergebnisGeladen', false);
      x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
      x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
    }).observes('schraubenausrichtung'),

    watchWinkelscheibe: (function () {
      var self = this;

      self.controllerFor('supercontroller').resetApplication();

      var schraubenausrichtung = self.controllerFor('application').get('model').schraubenausrichtung.objectAt(0);
      schraubenausrichtung.set('WinkelScheibe', self.get('winkelscheibe'));

      var application = this.controllerFor('application');
      var geometrie = this.controllerFor('geometrie');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      var deltaX = 0;

      if (Number(geometrie.get('scherflaechenVM')) === 2) {
        deltaX = Number(bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z')) / 2 + Number(bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z')) / 2;
      }

      x3d.set('winkelscheibe', self.get('winkelscheibe'));
      geometrie.setSchraubenlaenge();
      x3d.set('ergebnisGeladen', false);
      x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
      x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
    }).observes('winkelscheibe')

  });

});